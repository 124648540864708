import React, { useState } from "react";
import { Table, Menu, Select, Radio, Button, Divider } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import "./CustomFilterComponent.css";

const { Option } = Select;

// Custom filter and sorter component
const CustomFilterComponent = ({
  columnKey,
  onSort,
  onFilter,
  columnValues,
  closeDropdown,
}) => {
  const [filterCondition1, setFilterCondition1] = useState("equals");
  const [filterValue1, setFilterValue1] = useState(null);
  const [logicalOperator, setLogicalOperator] = useState("OR");
  const [filterCondition2, setFilterCondition2] = useState("equals");
  const [filterValue2, setFilterValue2] = useState(null);

  const handleSort = (order) => {
    onSort(columnKey, order);
    closeDropdown();
  };

  const handleFilterSubmit = () => {
    onFilter(columnKey, {
      condition1: filterCondition1,
      value1: filterValue1,
      operator: logicalOperator,
      condition2: filterCondition2,
      value2: filterValue2,
    });
    closeDropdown();
  };

  const handleCancel = () => {
    // Reset filter states
    setFilterCondition1("equals");
    setFilterValue1(null);
    setLogicalOperator("OR");
    setFilterCondition2("equals");
    setFilterValue2(null);

    // Clear any applied filters
    onFilter(columnKey, null);

    // Close the dropdown
    closeDropdown();
  };

  return (
    <Menu style={{ padding: 16, width: 250 }}>
      <Menu.Item
        onClick={() => handleSort("ascend")}
        style={{ color: "#4bafa6", fontWeight: "bold" }}
      >
        <ArrowUpOutlined /> Sort A to Z
      </Menu.Item>
      <Menu.Item
        onClick={() => handleSort("descend")}
        style={{ color: "#4bafa6", fontWeight: "bold" }}
      >
        <ArrowDownOutlined /> Sort Z to A
      </Menu.Item>

      <div style={{ fontWeight: "bold" }}>Custom Filter</div>

      <div style={{ display: "flex", gap: 10, marginBottom: 8 }}>
        <Select
          value={filterCondition1}
          onChange={setFilterCondition1}
          style={{ width: 100 }}
        >
          <Option value="equals">Equals</Option>
          <Option value="less_than">Less than</Option>
          <Option value="greater_than">Greater than</Option>
          <Option value="not_equals">Not equals</Option>
        </Select>
        <Select
          showSearch
          value={filterValue1}
          onChange={setFilterValue1}
          style={{ width: 100 }}
          placeholder="Select"
          filterOption={(input, option) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
        >
          {columnValues.map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </div>

      <Radio.Group
        onChange={(e) => setLogicalOperator(e.target.value)}
        value={logicalOperator}
        style={{ marginBottom: 8, color: "#ffc000" }}
      >
        <Radio value="AND">AND</Radio>
        <Radio value="OR">OR</Radio>
      </Radio.Group>

      <div style={{ display: "flex", gap: 8, marginBottom: 8 }}>
        <Select
          value={filterCondition2}
          onChange={setFilterCondition2}
          style={{ width: 100 }}
        >
          <Option value="equals">Equals</Option>
          <Option value="less_than">Less than</Option>
          <Option value="greater_than">Greater than</Option>
          <Option value="not_equals">Not equals</Option>
        </Select>
        <Select
          showSearch
          value={filterValue2}
          onChange={setFilterValue2}
          style={{ width: 100 }}
          placeholder="Select"
          filterOption={(input, option) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
        >
          {columnValues.map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleFilterSubmit}
          style={{
            color: "#000",
            backgroundColor: "#ffc000",
            borderColor: "#000",
          }}
        >
          Submit
        </Button>
      </div>
    </Menu>
  );
};

export default CustomFilterComponent;
