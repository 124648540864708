import React, { useState, useEffect } from "react";
import { Table, Typography, Button } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { v4 as uuidv4 } from "uuid";
import { ViewStratsTab as fetchViewStratsTab } from "../../../../../servies/services.js";

const { Title } = Typography;

const Strats = ({
  selectedPrimary,
  selectedSecondary,
  selectedTertiary,
  selectedAsOf,
  selectedDeals,
}) => {
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const itemsPerBatch = 15;

  const pageSize = 5;

  const generateColumns = (data, tableName) => {
    const baseColumns = [
      {
        title: tableName || "",
        dataIndex: "primVar",
        key: "primVar",
        fixed: "left",
        width: 200,
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Custom header alignment for primary column
          },
        }),
        render: (text, record) => {
          if (record.isTotal) {
            // Render "Total:" for summary rows
            return <strong>Total</strong>;
          }
          if (record.primVar) return <strong>{record.primVar}</strong>;
          if (record.secVar) return <strong>{record.secVar}</strong>;
          if (record.thrVar) return <strong>{record.thrVar}</strong>;
          return null;
        },
      },
    ];

    // Extract all keys from the first entry of the data to build columns
    const dataKeys = Object.keys(data?.[0] || {});

    const dynamicColumns = dataKeys
      .filter(
        (key) => key !== "primVar" && key !== "uniqueKey" && key !== "children"
      ) // Exclude these keys
      .map((key) => ({
        title: key.replace(/_/g, " "), // Format key names for display
        dataIndex: key,
        key,
        width: 150,
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Custom header alignment
          },
        }),
        render: (text, record) => {
          // Check if the column name contains a "$" symbol
          const isDollarColumn = key.toLowerCase().includes("($)");
          // Check if the text represents a whole number (integer)
          const isWholeOrIntegerWithZeroDecimal =
            /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text);
          // Check if the column name contains "count"
          const isCountColumn = key.toLowerCase().includes("count");
          // Check if the column name or text contains a % symbol
          const isPercentColumn = key.toLowerCase().includes("%");
          // Check if the value is "NA"
          const isNA = text === "NA";

          // Special handling for summary rows (isTotal)
          if (record.isTotal) {
            if (isNA) {
              return <div style={{ textAlign: "center" }}>{text}</div>;
            }
            if (isDollarColumn) {
              return (
                <strong style={{ textAlign: "right", display: "block" }}>
                  {text}
                </strong>
              );
            }
            if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
              return (
                <strong style={{ textAlign: "center", display: "block" }}>
                  {text}
                </strong>
              );
            }
            if (isPercentColumn) {
              return (
                <strong style={{ textAlign: "center", display: "block" }}>
                  {text}
                </strong>
              );
            }
            // Default alignment for other text
            return (
              <strong style={{ textAlign: "left", display: "block" }}>
                {text}
              </strong>
            );
          }

          // Regular rows
          if (isNA) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          if (isDollarColumn) {
            return <div style={{ textAlign: "right" }}>{text}</div>;
          }
          if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          if (isPercentColumn) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          return <div style={{ textAlign: "left" }}>{text}</div>;
        },
      }));

    return [...baseColumns, ...dynamicColumns];
  };

  const getViewStratsTab = async (primaryStrat) => {
    const data = {
      dealId: selectedDeals?.[0]?.value || "",
      userName: sessionStorage.getItem("user_name"),
      startDate: selectedAsOf?.label,
      prim: selectedPrimary.map((strat) => strat.label),
      sec: selectedSecondary?.value || "",
      thr: selectedTertiary?.value || "",
    };

    setLoading(true);

    try {
      const response = await fetchViewStratsTab(data);
      const result = response.data.result;

      const newTableData = {};
      const newPagination = {};
      const newExpandedRowKeys = {};

      result.forEach((resItem, index) => {
        // Parse data with unique keys
        const parsedData = resItem.results.map((item, idx) => ({
          ...item,
          uniqueKey: `row-${idx}-${uuidv4()}`,
        }));

        // Group data by dynamic flags
        const groupedData = groupDataByDynamicFlag(
          parsedData,
          selectedPrimary,
          selectedSecondary,
          selectedTertiary
        );

        // Prepare final structured data
        const finalData = Object.values(groupedData).map((primEntry) => {
          const { children: secChildren, ...restOfPrimEntry } = primEntry;

          const primData = {
            primVar: primEntry.primVar,
            uniqueKey: `row-prim-${uuidv4()}`,
            ...Object.keys(restOfPrimEntry).reduce((acc, key) => {
              if (key === "primVar" || key === "uniqueKey") {
                acc[key] = primEntry[key];
              } else {
                acc[key] = selectedSecondary?.value ? "" : restOfPrimEntry[key];
              }
              return acc;
            }, {}),
            ...(selectedSecondary?.value && {
              children: secChildren.map((secEntry) => {
                const { children: thrChildren, ...restOfSecEntry } = secEntry;

                const secData = {
                  ...restOfSecEntry,
                  uniqueKey: `row-sec-${uuidv4()}`,
                  secVar: secEntry.secVar,
                  ...Object.keys(restOfSecEntry).reduce((acc, key) => {
                    if (key === "secVar" || key === "uniqueKey") {
                      acc[key] = secEntry[key];
                    } else {
                      acc[key] = selectedTertiary?.value
                        ? ""
                        : restOfSecEntry[key];
                    }
                    return acc;
                  }, {}),
                  ...(selectedTertiary?.value && {
                    children: thrChildren.map((thrEntry) => ({
                      ...thrEntry,
                      uniqueKey: `row-thr-${uuidv4()}`, // Unique key for tertiary level
                    })),
                  }),
                };

                return secData;
              }),
            }),
          };

          return primData;
        });

        // Add totalCalculations as the last row
        const totalRow = {
          ...resItem.totalCalculations,
          uniqueKey: `row-total-${uuidv4()}`,
          isTotal: true, // Mark as total row for styling
        };

        finalData.push(totalRow); // Append the total row

        // Prepare expanded row keys for all levels
        const allKeys = finalData.reduce((keys, item) => {
          keys.push(item.uniqueKey); // Add primary key
          if (item.children) {
            item.children.forEach((secItem) => {
              keys.push(secItem.uniqueKey); // Add secondary key
              if (secItem.children && selectedTertiary?.value) {
                secItem.children.forEach((thrItem) => {
                  keys.push(thrItem.uniqueKey); // Add tertiary key
                });
              }
            });
          }
          return keys;
        }, []);

        // Store data and expanded keys
        newTableData[selectedPrimary[index].value] = finalData;
        newPagination[selectedPrimary[index].value] = { current: 1, pageSize };
        newExpandedRowKeys[selectedPrimary[index].value] = allKeys;
      });

      // Update the state
      setTableData((prev) => ({ ...prev, ...newTableData }));
      setPagination((prev) => ({ ...prev, ...newPagination }));
      setExpandedRowKeys((prev) => ({ ...prev, ...newExpandedRowKeys }));
      setDisplayData(result.slice(0, itemsPerBatch));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupDataByDynamicFlag = (data) => {
    return data.reduce((acc, curr) => {
      const { primVar, secVar, thrVar, ...rest } = curr;

      // Create unique keys for each level
      const primUniqueKey = `prim-${primVar}-${uuidv4()}`;
      const secUniqueKey = `sec-${secVar}-${uuidv4()}`;
      const thrUniqueKey = `thr-${thrVar}-${uuidv4()}`;

      // console.log({ primVar });

      // Initialize primary level
      if (!acc[primVar]) {
        acc[primVar] = {
          primVar,
          ...rest,
          uniqueKey: primUniqueKey,
          children: [],
        };
      }

      const primChild = acc[primVar];

      // Check if the secondary level exists and create it if not
      let secChild = primChild.children.find(
        (child) => child.secVar === secVar
      );
      if (!secChild) {
        secChild = {
          secVar,
          ...rest,
          uniqueKey: secUniqueKey,
          children: [],
        };
        // console.log({ secChild });

        primChild.children.push(secChild);
      }

      // Push the tertiary data to the secondary child
      secChild.children.push({
        thrVar,
        ...rest,
        uniqueKey: thrUniqueKey,
      });

      return acc;
    }, {});
  };

  // Scroll event handler
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth } =
      event.target;

    // Only proceed if we are at the bottom of the vertical scroll
    const isVerticalScrollBottom = scrollTop + clientHeight >= scrollHeight - 5;
    const isAtHorizontalStart = scrollLeft === 0; // Optional: Use this if you want to avoid loading on horizontal scrolls

    if (isVerticalScrollBottom && !loading && isAtHorizontalStart) {
      loadMoreData();
    }
  };

  // Load next batch of data
  const loadMoreData = () => {
    if (displayData.length >= tableData.length) return; // Stop if no more data

    setLoading(true);
    const nextBatch = tableData.slice(
      displayData.length,
      displayData.length + itemsPerBatch
    );
    setDisplayData((prevData) => [...prevData, ...nextBatch]);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedPrimary.length > 0) {
      const newTableData = {};
      selectedPrimary.forEach((primary) => {
        newTableData[primary.value] = [];
      });
      setTableData(newTableData);
      getViewStratsTab(selectedPrimary);
    }
  }, [selectedPrimary, selectedSecondary, selectedTertiary]);

  const handlePageChange = (primaryStrat, page) => {
    setPagination((prev) => ({
      ...prev,
      [primaryStrat]: { ...prev[primaryStrat], current: page },
    }));
  };

  const exportDynamicPDF = () => {
    const doc = new jsPDF();

    Object.entries(tableData).forEach(([primaryKey, primaryData], index) => {
      // Add a new page for each primary group
      if (index > 0) doc.addPage();

      doc.setFontSize(8); // Decrease the title font size
      doc.text(primaryKey, 14, 20); // Title for the primary group
      doc.setFontSize(10); // Reset font size for the table

      // Prepare rows and columns dynamically
      const rows = [];
      const columns = [];

      // Helper function to flatten hierarchical data
      const flattenData = (data, level = 0) => {
        data.forEach((item) => {
          const row = {
            ...item,
            firstColumnValue:
              level === 0
                ? item.primVar // Primary level
                : level === 1
                ? `  ${item.secVar}` // Indented for secondary level
                : `    ${item.thrVar}`, // Further indented for tertiary level
            level,
            children: undefined, // Remove children to prevent recursive issues
          };
          rows.push(row);

          // Recursively process children if available
          if (item.children && item.children.length > 0) {
            flattenData(item.children, level + 1);
          }
        });
      };

      // Flatten the data
      flattenData(primaryData);

      // Extract columns from the first data row
      if (rows.length > 0) {
        const firstRow = rows[0];
        Object.keys(firstRow).forEach((key) => {
          if (
            key !== "uniqueKey" &&
            key !== "level" &&
            key !== "children" &&
            key !== "firstColumnValue" &&
            key !== "primVar" // Exclude the "primVar" column
          ) {
            columns.push({ header: key, dataKey: key });
          }
        });
      }

      // Add the primaryKey as the first column
      columns.unshift({
        header: primaryKey,
        dataKey: "firstColumnValue",
      });

      // Modify the last row to include "Total" in the first column
      if (rows.length > 0) {
        rows[rows.length - 1].firstColumnValue = "Total"; // Set "Total" for the last row's first column
      }

      // Calculate page width and adjust scale
      const pageWidth = doc.internal.pageSize.getWidth();
      const tableWidth = columns.length * 65; // Approximate column width
      const scaleFactor = tableWidth > pageWidth ? pageWidth / tableWidth : 1;

      // Add the table
      doc.autoTable({
        startY: 25, // Start below the title
        head: [columns.map((col) => col.header)], // Generate headers dynamically
        body: rows.map(
          (row) => columns.map((col) => row[col.dataKey]) // Map each cell to the corresponding column
        ),
        theme: "striped", // Table styling (options: grid, striped, plain)
        headStyles: {
          fillColor: [0, 77, 64], // Light gray background for header
          textColor: [255, 255, 255],
          // fontStyle: "bold",
          fontSize: 20 * scaleFactor, // Adjust font size based on scale
          cellPadding: 8 * scaleFactor,
          overflow: "linebreak", // Enable text wrapping for headers
          minCellHeight: 16 * scaleFactor,
        },
        styles: {
          fontSize: 24 * scaleFactor, // Adjust font size for table body
          cellPadding: 4 * scaleFactor,
          overflow: "linebreak", // Enable text wrapping
          halign: "center", // Center align text
        },
        columnStyles: {
          0: { cellWidth: "wrap", halign: "left" }, // Align the first column to the left
        },
        bodyStyles: {
          minCellHeight: 2, // Adjust cell height for better spacing
        },
        rowPageBreak: "avoid", // Avoid splitting rows across pages
        willDrawCell: (data) => {
          // Apply styling for the last row
          if (data.row.index === rows.length - 1) {
            doc.setFont("helvetica", "bold"); // Set font to bold
            doc.setTextColor("#19191A"); // Set text color to black
          }
        },
      });
    });

    // Save the PDF
    doc.save("Strats.pdf");
  };

  return (
    <div className="table-container">
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 4 }}
      >
        <Button
          type="primary"
          onClick={exportDynamicPDF}
          style={{
            backgroundColor: "#194d40",
            width: 120,
          }}
        >
          Download PDF
        </Button>
      </div>
      {selectedPrimary && selectedPrimary.length > 0 ? (
        selectedPrimary.map((primary) => (
          <div key={primary.value}>
            <Title level={3}>{primary.label}</Title>
            <Table
              className="performance-table"
              columns={generateColumns(tableData[primary.value], primary.label)}
              dataSource={tableData[primary.value] || []}
              rowKey={(record) => record.uniqueKey}
              rowClassName={(record) => (record.isTotal ? "total-row" : "")}
              // pagination={{
              //   current: pagination[primary.value]?.current || 1,
              //   pageSize,
              //   total: tableData[primary.value]?.length || 0,
              //   onChange: (page) => handlePageChange(primary.value, page),
              // }}
              pagination={false}
              bordered
              loading={loading}
              expandable={{
                expandRowByClick: true,
                childrenColumnName: "children",
                expandedRowKeys: expandedRowKeys[primary.value] || [],
                onExpand: (expanded, record) => {
                  setExpandedRowKeys((prev) => {
                    const currentKeys = prev[primary.value] || [];
                    return {
                      ...prev,
                      [primary.value]: expanded
                        ? [...currentKeys, record.uniqueKey]
                        : currentKeys.filter((key) => key !== record.uniqueKey),
                    };
                  });
                },
                rowExpandable: (record) =>
                  record.children && record.children.length > 0,
              }}
              scroll={{ x: "max-content", y: "calc(100vh - 100px)" }}
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Strats;
