import React, { createContext, useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useSnackbar } from "notistack";

export const Context = createContext();

const getPlatform = sessionStorage.getItem("platform_name") || "WSFS";
const getUserName = sessionStorage.getItem("user_name");
const getEmail = sessionStorage.getItem("emailid");

const finalUserName = getUserName;
const finalInvs = {
  platform: getPlatform,
  getUserName: finalUserName,
  email: getEmail,
};
const stringifiedfinalInvs = JSON.stringify(finalInvs);
// console.log("stringifiedfinalInvs", stringifiedfinalInvs);

const ContextProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = useState(null);
  const [response, setResponse] = useState(""); // State to store the response

  //   Examples

  const [input, setInput] = useState("");
  const [recentPrompt, setRecentPrompt] = useState("");
  const [prevPrompts, setPrevPrompts] = useState([]);
  const [allPrompts, setAllPrompts] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState("");
  const [resultData, setResultData] = useState("");
  const [currentPromptIndex, setCurrentPromptIndex] = useState(-1);
  const [showPrevPrompts, setShowPrevPrompts] = useState(false);
  const [displayedPrompts, setDisplayedPrompts] = useState([]); // State for the displayed recent prompt
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isModalFeedbackVisible, setIsModalFeedbackVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [socket, setSocket] = useState(null);
  const [docid, setDocid] = useState("");

  const showModal = () => {
    setIsModalFeedbackVisible(true);
  };

  // console.log({ docid });

  const handleOk = () => {
    if (!comment.trim()) {
      enqueueSnackbar("Comment cannot be empty. Please enter your feedback.", {
        variant: "warning",
      });
      setComment("");

      return;
    }

    if (!resultData) {
      enqueueSnackbar("Please select or ask a question to provide feedback", {
        variant: "info",
      });
      setComment("");

      return;
    }

    if (!docid) {
      enqueueSnackbar("Please select or ask a question to provide feedback", {
        variant: "info",
      });
      setComment("");
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      // Send the feedback comment to the WebSocket server
      socket.send(JSON.stringify({ comment }));

      // Listen for the WebSocket response
      socket.onmessage = (event) => {
        const response = event.data;

        enqueueSnackbar(response || "Feedback submitted successfully!", {
          variant: "success",
        });
      };

      // Close the modal and reset the comment
      setIsModalFeedbackVisible(false);
      setComment("");
    } else {
      enqueueSnackbar(
        "Failed to connect to the server. Please try again later.",
        {
          variant: "error",
        }
      );
    }
  };

  const handleCancelFeedback = () => {
    setIsModalFeedbackVisible(false);
  };

  useEffect(() => {
    // Define platform and docId parameters
    const platformData = { platform: getPlatform };
    const docId = docid;

    // Construct WebSocket URL
    const platformString = encodeURIComponent(JSON.stringify(platformData));
    const wsUrl = `wss://www.ask.intainabs.com/ida/feedback/${platformString}?docId=${docId}`;

    // Initialize WebSocket connection
    const ws = new WebSocket(wsUrl);
    setSocket(ws);

    // Clean up WebSocket connection when component unmounts
    return () => {
      if (ws) ws.close();
    };
  }, []);

  useEffect(() => {
    let pingInterval;
    try {
      const newClient = new W3CWebSocket(
        `wss://www.ask.intainabs.com/ida/${stringifiedfinalInvs}`
      );

      newClient.onopen = () => {
        console.log("WebSocket Client Connected");

        // Start sending ping messages every 30 seconds (or adjust as needed)
        pingInterval = setInterval(() => {
          if (newClient.readyState === WebSocket.OPEN) {
            newClient.send(JSON.stringify({ type: "ping" })); // Send a ping message
            console.log("Ping sent to keep the connection alive.");
          }
        }, 1200000); // 30 seconds interval
      };

      newClient.onmessage = (message) => {
        // console.log("Received:", message.data);
        setResultData(message.data); // Set the response in the state

        setLoading(false);
      };

      newClient.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      newClient.onclose = () => {
        console.log("WebSocket Client Closed");
        clearInterval(pingInterval); // Stop pinging when the connection is closed
      };

      setClient(newClient);
    } catch (error) {
      console.error("Failed to create WebSocket connection:", error);
    }

    return () => {
      if (client) {
        client.close(); // Clean up when the component unmounts
        clearInterval(pingInterval); // Stop pinging when component unmounts
      }
    };
  }, [stringifiedfinalInvs]);

  // Parse resultData and extract docid
  useEffect(() => {
    if (resultData) {
      try {
        const parsedData = JSON.parse(resultData);
        setDocid(parsedData.docId || ""); // Set docid if available
      } catch (error) {
        console.error("Failed to parse resultData:", error);
      }
    }
  }, [resultData]);

  // const handleSaveClick = () => {
  //   const finalPrompt = recentPrompt; // Get the current recent prompt
  //   if (finalPrompt.trim() !== "") {
  //     savePromptToHistory(finalPrompt); // Save prompt to history
  //     setShowPrevPrompts(true); // Show previous prompts
  //     console.log("Saved prompt to history:", finalPrompt); // Optional logging
  //   }
  // };

  // Function to handle clicking the save button
  const handleSaveClick = () => {
    // Check if recentPrompt is not empty and not already in displayedPrompts
    if (
      recentPrompt.trim() !== "" &&
      !displayedPrompts.includes(recentPrompt.trim())
    ) {
      setDisplayedPrompts((prev) => [...prev, recentPrompt.trim()]);
      setSelectedPrompt(recentPrompt);
      setShowPrevPrompts(true); // Show the prompt
      // console.log("Saved prompt to history:", recentPrompt); // Optional logging
    }
  };

  const loadPrompt = async (prompt, index) => {
    setRecentPrompt(prompt);
    setSelectedPrompt(prompt);
    setCurrentPromptIndex(index); // Set the correct index
    await onSent(prompt);
  };

  const onSent = async (prompt) => {
    if (client && client.readyState === WebSocket.OPEN) {
      setResultData("");
      setLoading(true);
      setShowResult(true);

      let finalPrompt = prompt !== undefined ? prompt : input;

      // Ensure prompt is not empty
      if (finalPrompt.trim() !== "") {
        setPrevPrompts((prev) => {
          if (!prev.includes(prompt)) {
            const updatedPrompts = [...prev, prompt];
            setCurrentPromptIndex(updatedPrompts.length - 1); // Update index after adding prompt
            return updatedPrompts;
          }
          return prev; // Return the same array if it already exists in history
        });
        // Send the prompt to the server
        client.send(JSON.stringify(`${finalPrompt}`));
        setRecentPrompt(finalPrompt);
        setInput(""); // Clear the input
        // console.log("Question sent:", finalPrompt);
      } else {
        console.log("Prompt is empty or undefined.");
      }
    } else {
      console.log("WebSocket is not connected.");
    }
  };

  // const savePromptToHistory = (prompt) => {
  //   setPrevPrompts((prev) => {
  //     if (!prev.includes(prompt)) {
  //       const updatedPrompts = [...prev, prompt];
  //       setCurrentPromptIndex(updatedPrompts.length - 1); // Update index after adding prompt
  //       return updatedPrompts;
  //     }
  //     return prev; // Return the same array if it already exists in history
  //   });
  // };

  const contextValue = {
    prevPrompts,
    setPrevPrompts,
    onSent,
    setRecentPrompt,
    setCurrentPromptIndex,
    currentPromptIndex,
    recentPrompt,
    showResult,
    loading,
    resultData,
    input,
    showPrevPrompts,
    selectedPrompt,
    isModalFeedbackVisible,
    comment,
    setComment,
    handleOk,
    handleCancelFeedback,
    showModal,
    setIsModalFeedbackVisible,
    setSelectedPrompt,
    setInput,
    setShowResult,
    handleSaveClick,
    setDocid,
    displayedPrompts,
    setDisplayedPrompts,
    loadPrompt,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
