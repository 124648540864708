/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  IssuerIcons,
  ServicerMainDashboard,
  Investor_DashBoard,
  PendingUsersDashBoard,
  Issuer_DashBoard,
} from "./menu";
import LinkItem from "../linkItem";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Intan_AI from "../../images/Intan_AI.svg";
import IntainLogo from "../../images/IntainLogo.png";
import help from "../../images/help.svg";
import "./sidebar.css";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: sessionStorage.getItem("user_name"),
      OrgName: sessionStorage.getItem("OrgName"),
      userrole: sessionStorage.getItem("userrole"),
      ChannelName: sessionStorage.getItem("ChannelName"),
      loading: false,
      getLoansLoader: false,
      open: false,
      message: "",
      currentmenu: [],
      menu_loader: false,
      title: "",
      visible: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  logoutBtn() {
    sessionStorage.clear();
    window.location.replace("/");
  }

  async componentDidMount() {
    const { currentUser } = this.state;
    if (currentUser !== null) {
      const firstname = currentUser.charAt(0).toUpperCase();
      this.setState({ currentUser: firstname });
    }

    // Simplify currentmenu logic
    let currentMenu = [];
    switch (true) {
      case currentUser === "Trustee" || currentUser === "AdminAG":
        currentMenu = IssuerIcons;
        break;
      case currentUser.includes("Investor") ||
        currentUser.includes("IS_stoa") ||
        currentUser.includes("AngeloGordon"):
        currentMenu = Investor_DashBoard;
        break;
      case currentUser === "Issuer" || currentUser === "Issuer2":
        currentMenu = Issuer_DashBoard;
        break;
      case currentUser === "admin":
        currentMenu = PendingUsersDashBoard;
        break;
      default:
        currentMenu = ServicerMainDashboard;
    }

    this.setState({ currentmenu: currentMenu, menu_loader: true });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ visible: false });
    }
  }

  render() {
    const { currentmenu, menu_loader, visible, currentUser } = this.state;
    const { activeComponent } = this.props;

    return (
      <div className="sidebar">
        {menu_loader && (
          <>
            <div className="sidebar-icons">
              <div className="sidebar-logo">
                <img src={Intan_AI} alt="Intan AI" className="logo-intan" />
                <img
                  src={IntainLogo}
                  alt="Intain Logo"
                  className="logo-intain-hidden"
                />
              </div>

              <ul>
                {currentmenu.map((item) => (
                  <li
                    key={item.title}
                    className={`issure-icons${
                      item.title === "ESMA Reporting" &&
                      sessionStorage.getItem("isESMA_Flag") === "No"
                        ? " disabledESMA"
                        : ""
                    }`}
                  >
                    <Link
                      to={item.linkto}
                      title={item.title}
                      className={
                        activeComponent === item.title && !visible
                          ? "issure-icon-active"
                          : "issure-icon"
                      }
                    >
                      <img alt="issuer icon" src={item.icon} />
                      <span className="sidebar-text content-show">
                        {item.title}
                      </span>
                      {/* Text is hidden by default, shows on hover */}
                    </Link>

                    {item.subitems && (
                      <ul>
                        <div className="arrow-left"></div>
                        <div className="arrow_box">
                          {item.subitems.map((subItem) => (
                            <li key={subItem.title}>
                              <LinkItem
                                to={subItem.linkto}
                                title={subItem.title}
                              ></LinkItem>
                            </li>
                          ))}
                        </div>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {currentUser.includes("Investor") && (
              <div
                className={`AI_Icon ${
                  visible ? "issure-icon-active" : "issure-icon"
                }`}
                ref={this.wrapperRef}
                onClick={() => this.setState({ visible: !visible })}
              >
                {/* <img alt="issuer icon" src={AIChat} /> */}
                <h1 className="Beta">BETA</h1>
              </div>
            )}

            <div className="sidebar-footer">
              <ul>
                <li className="sidebar-item">
                  <div className="sidebar-item-content">
                    <img src={help} alt="Help" />
                    <span className="sidebar-text content-show">FAQ</span>
                  </div>
                </li>
                <li className="sidebar-item">
                  <div className="sidebar-item-content">
                    <NotificationsIcon className="notificationcolor" />
                    <span className="sidebar-text content-show">
                      Notifications
                    </span>
                  </div>
                </li>
                <li className="sidebar-item">
                  <div
                    className="sidebar-item-content"
                    onClick={this.logoutBtn}
                  >
                    <div className="sidebar-item-profile">
                      <p>{currentUser}</p>
                    </div>
                    <span className="sidebar-text content-show">
                      Profile & More
                      <svg
                        className="w-5 h-5 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round" // Corrected here
                          strokeLinejoin="round" // Corrected here
                          strokeWidth="2" // Corrected here
                          d="m9 5 7 7-7 7"
                        />
                      </svg>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    );
  }
}
